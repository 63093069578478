@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Ubuntu:wght@300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
  font-weight: bold !important;
}

p {
  font-family: 'Roboto', sans-serif !important;
  margin-bottom: 0 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.navbar-menuItem{
   padding: 8px 25px !important;
   color: white !important;
   font-weight: 600 !important;
   border: 1px solid transparent !important;
}

.navbar-menuItem-active{
  padding: 8px 25px !important;
  color: white !important;
  font-weight: bold !important;
  border: 1px solid white !important;
  border-radius: 5px !important;
}

.navbar-button{
  background-color: #6e067d;
}

.nav-pills .nav-link.active{
  background-color: #253847 !important;
  color: white !important;
}

.nav-pills .nav-link{
  color: #253847 !important;
}

.caseworking-card{
  display:inline-block;
  background:
   linear-gradient(#fff 0 0) padding-box,
   linear-gradient(to bottom, #360af7 49%, #de01fe) border-box;
   border: 2px dashed #fff;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: white;
  padding: 10px;
  position:absolute;
  left: 50% ;
  transform: translateX(-50%);
  margin-top:-70px;
  top: 0;
  margin-bottom:30px;
}

.main-caseworking-container:hover{
  background: #3520e6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3520e6, #d901fc);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3520e6, #d901fc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white !important;
}

.main-caseworking-container:hover button{
  background: #ffd401;
  color: black !important;;
}

.caseworking-card-btn{
  padding: 10px 20px;
  border-radius: 5px;
  border: none !important;
  background: #3520e6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3520e6, #d901fc);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3520e6, #d901fc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white !important;
  font-weight: 500;
  cursor: pointer;
}



.caseworking{
  background-image: repeating-linear-gradient(-45deg,#dfe6e9,#dfe6e9 0.7000000000000001px,#fff 0,#fff 50%);
  background-size: 7px 7px;
}

.benifits-card{
  background: #3520e6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3520e6, #d901fc);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3520e6, #d901fc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white !important;
}

.product-card{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px!important;
    cursor: pointer;
    height: 400px !important;
    position: relative;
}

.price-section{
  background-color: rgba(240,255,255,.295);
  border-radius: 6px;
  padding: 10px 15px;
  margin-right: 14px;
  position: absolute;
  right: 0;
}

.product-card-bottom-section{
  bottom: 20px;
  left: 20px;
  position: absolute
}

.product-card-bottom-section h4{
  color: #fff;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: .7px;
  margin-right: 5px;
}

.product-card-bottom-section h5{
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: .7px;
}

.product-card-cta{
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 15px;
}

.product-card-cta-btn{
  background-color: #f1c40f;
  border: none;
  border-radius: 5px;
  color: #000;
  font-size: 13px;
  font-weight: 700;
  padding: 9px 19px;
  transition: .3s;
}


.about-silva-cta-container{
    background-image: url("/public/assets/img/footerctabg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
}

.benifit-card-image::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000015;
}

.benifit-card-image{
  position: relative;
}

.modal-content{
  border-radius: 1rem !important;
}
/* 
.imageBackground{
  background: url('/public/assets/img/transparent_hero_image.png');
  background-size: cover;
  background-position: center;
  width: 100%;
} */


.modal-content div::-webkit-scrollbar {
  display: none;
}


/* .reviews-carousel .slick-dots li.slick-active button:before{
  color: #d901fc !important;
} */

.reviews-carousel .slick-dots li{
  position: relative;
    display: inline-block;
    width: 20px;
    height: 6px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border-radius: 30px;
    border: 1px solid #d901fc;
}

.reviews-carousel .slick-dots li button{
  font-size: 0;
    line-height: 0;
    border-radius: 30px;
    display: block;
    width: 20px;
    height: 100%;
    border-radius: 30px;
    padding: 0 !important;
    cursor: pointer;
    /* color: transparent; */
    border: 0;
    outline: none;
    /* background: transparent; */
}
.reviews-carousel .slick-dots li.slick-active button {
  background: #3520e6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3520e6, #d901fc);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3520e6, #d901fc);
  padding: 0;
  width: 100% !important;
  border: none !important;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '' !important;
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.reviews-carousel .slick-dots li.slick-active{
  width: 50px !important;
}

.authherocontainer{
  background: #3520e6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3520e6, #d901fc);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3520e6, #d901fc);
  width: 100%;
}


.authcard-icon-section{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 10px;
  display:inline-block;
  background:
   linear-gradient(#fff 0 0) padding-box,
   linear-gradient(to bottom, #360af7 49%, #de01fe) border-box;
   border: 2px dashed #fff;
  background-color: white;
  /* padding: 10px; */
  /* position:absolute; */
  /* left: 50% ; */
  /* transform: translateX(-50%); */
  /* margin-top:-70px; */
  /* top: 0;
  margin-bottom:30px; */
  /* border: 2px dashed #282282; */
  /* width: 120px;
  height: 120px; */
  /* border-radius: 50%; */
    /* position:absolute;
  left: 50% ;
  transform: translateX(-50%);
  margin-top:-70px;
  top: 0;
  margin-bottom:30px; */
  /* background-color: white; */

}


.cardhover-border-visible:hover{
  border: 2px dashed rgb(182, 182, 182);
  border-radius: 30px;
}

.cardhover-border-visible{
  border: 2px dashed transparent;
  padding: 21px;
  cursor: pointer;
}

input[type="radio"]
{
  appearance: none;
}

input[type="radio"] + label
{
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-weight: 500;
}

input[type="radio"] + label::before
{
  content: "";
  display: inline-block;
  width: 20px;
  aspect-ratio: 1;
  border: 1px solid #7912d5;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 1;
  transition: all 0.3s;
}

input[type="radio"] + label::after
{
  content: "";
  display: inline-block;
  width: 10px;
  aspect-ratio: 1;
  border: 1px solid #7912d5;
  background: #7912d5;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s;
}

input[type="radio"]:checked + label::after
{
  opacity: 1;
}

.accordion-button{
  background-color: #f8f5fc !important;
  box-shadow: none !important;
  position: relative;
  display: block !important;
}


.accordion-button::after{
  position: absolute;
  right: -10px;
  top: 43%;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM135 241c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l87 87 87-87c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 345c-9.4 9.4-24.6 9.4-33.9 0L135 241z'/%3E%3C/svg%3E") !important; */
}

/* .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM377 271c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-87-87-87 87c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 167c9.4-9.4 24.6-9.4 33.9 0L377 271z'/%3E%3C/svg%3E") !important;
} */


.accordion-button::before{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff !important;
  border: 1px solid #9961cd;
  position: absolute;
  content: '';
  right: -15px;
  top: 38%;
}



.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background: #E0EAFC;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #CFDEF3, #E0EAFC);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #CFDEF3, #E0EAFC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#5c6bb9, #bd00e7);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#5c6bb9, #bd00e7);
  overflow: hidden;
}

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.7) !important;
  backdrop-filter: saturate(200%) blur(25px);
}

.nav-pills .logintabs{
  color:  #253847 !important;
  border: 1px solid #253847 !important;
}


.theme-button{
  background: #3520e6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3520e6, #d901fc);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3520e6, #d901fc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white !important;
  border: none !important;
}

.nav-pills .logintabs.active{
  background-color: transparent !important;
  background: #3520e6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3520e6, #d901fc);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3520e6, #d901fc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white !important;
  border: transparent !important;
}

.border-black{
  border: 1px solid #253847 !important;
}

.benifits_image_bg{
  background-image: url('../public/assets/img/benifits_bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact_bg{
  background-image: url('../public/assets/img/user-home-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}



.reviews-carousel .slick-dots{
  bottom: -65px;
}

.padding-bottom-80{
  padding-bottom: 80px !important;
}


.image-circle {
  width: 70px;
  height: 70px;
  border: double 2px transparent;
  border-radius: 50%;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #f00,#3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: inline-block;
}

.blank-height{
  height: 350px;
}


.submit-modal .modal-content{
  background-image: url('/public/assets/img/sumbitcase-bg.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


@media (min-width: 992px) and (max-width:1199px){
  .modal-lg, .modal-xl {
      --bs-modal-width: 910px !important;
  }
  .submit-modal .container{
    height: 600px;
  }
  .forgot-password-modal .modal-lg, .modal-xl{
    --bs-modal-width: 600px !important;
  }
}

@media (min-width:768px) and (max-width: 991px){
  .modal-lg, .modal-xl {
      --bs-modal-width: 700px !important;
  }
  .forgot-password-modal .modal-lg, .modal-xl{
    --bs-modal-width: 600px !important;
  }
}

@media (min-width:1200px){
  .modal-lg, .modal-xl {
      --bs-modal-width: 1200px !important;
  }
  .forgot-password-modal .modal-lg, .modal-xl{
    --bs-modal-width: 800px !important;
  }
  .submit-modal .container,.pickcase-modal .pickcase-first{
    height: 600px;
  }
}

@media (max-width:1000px){
  .submit-modal .modal-content{
    background-image: url('/public/assets/img/submit-case-bg2.webp');
  }
}

.pickcase-modal .modal-content{
  background-image: url('/public/assets/img/pickup-case.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pickcase-modal .pickcase-first{
  height: 600px;
}

.viewcase-modal .container{
  height: 400px !important;
}

.not-found-one{
  height: 500px !important;
  width: 500px !important;
  border-radius: 50%;
  background-color: #d901fc47;
  position: absolute;
  top: -14px;
  left: -14px;
  filter: blur(80px);
}

.not-found-two{
  height: 500px !important;
  width: 500px !important;
  border-radius: 50%;
  background-color: #3020ff45;
  position: absolute;
  bottom: -14px;
  right: -14px;
  filter: blur(80px);
}

.gradient-heading{
  background: -webkit-linear-gradient(#3520e6, #d901fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dancing-script{
  font-family: "Dancing Script", cursive !important;
  font-size: 25px !important;
  color:#FFDB31 !important;
}





.pickcase-modal .accordion-item{
    border: none !important;
    border-radius: 15px !important;
}

.pickcase-modal .accordion-button{
  border-radius: 15px !important;
}


#inputfield {

  display: inline-block;
  padding: 0 16px 0 12px;
  width: 100%;
  line-height: 30px;
  height: 35px;
  border: 1px solid #dddbda;
  border-radius: 4px;
  background-color: #fff;
  color: rgb(8, 7, 7);
  transition: border .1s linear, background-color .1s linear;


}

#inputfield:focus {
  border-color: #1b96ff !important;
  color: black !important;
  box-shadow: rgb(1, 118, 211) 0px 0px 3px 0px !important;
}

#textareanew {

  display: inline-block;
  padding: 0 16px 0 12px;
  width: 100%;
  line-height: 30px;
  border: 1px solid #dddbda;
  border-radius: 4px;
  background-color: #fff;
  color: rgb(8, 7, 7);
  transition: border .1s linear, background-color .1s linear;


}

#textareanew:focus {
  border-color: #1b96ff !important;
  color: black !important;
  box-shadow: rgb(1, 118, 211) 0px 0px 3px 0px !important;
}

#appointbtn {

  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  border-radius: 500px;
  transition-property: background-color, border-color, color, box-shadow, filter;
  transition-duration: .3s;
  border: 1px solid transparent;
  letter-spacing: 2px;
  min-width: 100%;
  text-transform: uppercase;
  white-space: normal;
  font-weight: 600;
  text-align: center;
  padding: 17px 48px;
  color: #fff;
  background-color: #8019da;
  height: 48px;


}

#appointbtn :hover {
  transform: scale(1.04);
  background-color: #21e065;
}


.brief-container{
  background-color: #1b1172;
  width: 100%;
}


@media screen and (max-width:767px) {
  .mobilehide{
    display: none !important;
  }
  .mobileshow{
    display: block !important;
  }
  
}
@media screen and (min-width:768px) {
  .mobilehide{
    display: block !important;
  }
  .mobileshow{
    display: none !important;
  }
}
